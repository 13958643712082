// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contatti-tsx": () => import("./../../../src/pages/contatti.tsx" /* webpackChunkName: "component---src-pages-contatti-tsx" */),
  "component---src-pages-cookie-law-tsx": () => import("./../../../src/pages/cookieLaw.tsx" /* webpackChunkName: "component---src-pages-cookie-law-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-legge-124-tsx": () => import("./../../../src/pages/legge-124.tsx" /* webpackChunkName: "component---src-pages-legge-124-tsx" */),
  "component---src-pages-maestro-tsx": () => import("./../../../src/pages/maestro.tsx" /* webpackChunkName: "component---src-pages-maestro-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-school-tsx": () => import("./../../../src/pages/school.tsx" /* webpackChunkName: "component---src-pages-school-tsx" */),
  "component---src-templates-author-tsx": () => import("./../../../src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */),
  "component---src-templates-teacher-tsx": () => import("./../../../src/templates/teacher.tsx" /* webpackChunkName: "component---src-templates-teacher-tsx" */)
}

